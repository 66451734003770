// PlanSubscription.js
import React, { useState, useEffect } from 'react';
import './PlanSubscription.css'; // Import the new CSS file
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useWebSocket } from './WebSocketContext';

const PlanSubscription = () => {

  const [loading, setLoading] = useState(false); // State to track loading status
  const navigate = useNavigate();
  // Initialize Stripe using loadStripe
  const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
  const stripePromise = loadStripe(stripeKey);
  

  const handleCancelSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
      
      // Create a session to redirect the customer to the Stripe portal
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscription/manage`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        alert('Failed to redirect to the subscription management portal. Please try again later.');
      }
    } catch (error) {
      console.error('Error redirecting to the Stripe portal:', error);
      alert('An error occurred while attempting to cancel your subscription. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = async (priceId) => {
    setLoading(true); // Start loading when checkout is initiated
    try {
      // Get the Stripe instance after the promise resolves
      const stripe = await stripePromise;

      if (!stripe) {
        console.error('Stripe failed to load');
        alert('Stripe failed to load. Please try again later.');
        return;
      }

      // Get user session to retrieve JWT token
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();

      // Create a checkout session on the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkout`,
        { priceId },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
          },
        }
      );

      // Redirect to Stripe Checkout
      const { sessionId } = response.data;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        console.error(result.error.message);
        alert('Error during checkout. Please try again.');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to start the checkout process. Please try again.');
    }finally {
        setLoading(false); // Stop loading once the process completes
    }
  };

  return (
    <div className="plan-subscription-modal">

      <div className="plan-subscription-content">
        <div className="subscription-header">
          <h2 className="subscription-title">Choose Your Subscription</h2>

        </div>
        {loading && <div className="loading-spinner">Loading...</div>} {/* Show spinner if loading */}
        <table className="subscription-table">
          <tbody>
            <tr>
              <td className="tier">
                <h4>Adventurer</h4>
                <img src="/images/adventurer.png" alt="Adventurer Plan" className="subscription-image" />
                <p>Includes up to 500 notes for planning your next adventurer and advanced geo-location lookup for more accurate recommendations.</p>
                <p>$49.00/year</p>
              </td>
              <td className="tier">
                <h4>Globe Trotter</h4>
                <img src="/images/traveler.webp" alt="Globe Trotter Plan" className="subscription-image" />
                <p>For the ability to create more than 500 notes and enhanced AI recommendations.</p>
                <p>$89.00/year</p>
              </td>
            </tr>
            <tr>
              <td className="tier-button-cell">
                <button
                  className="plan-subscription-button"
                  onClick={() => handleCheckout(process.env.REACT_APP_STRIPE_Enhanced_Price_ID)}
                  disabled={loading} // Disable button while loading
                >
                  Choose Adventurer
                </button>
              </td>
              <td className="tier-button-cell">
                <button
                  className="plan-subscription-button"
                  onClick={() => handleCheckout(process.env.REACT_APP_STRIPE_Premium_Price_ID)}
                  disabled={loading} // Disable button while loading
                >
                  Choose Globe Trotter
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="plan-subscription-button cancel"
          onClick={() => navigate('/account-settings?settings=true')}
          disabled={loading} // Disable button while loading
        >
          Back
        </button>
        <a
            href="#"
            className="cancel-subscription-link"
            onClick={handleCancelSubscription}
            style={{ pointerEvents: loading ? 'none' : 'auto', opacity: loading ? 0.5 : 1 }}
          >
            Manage Existing Subscription
          </a>
      </div>
    </div>
  );
};

export default PlanSubscription;
