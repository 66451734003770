// NotFoundPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.css'; // Add styles here

const NotFoundPage = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="not-found-description">
          It looks like you’ve found a broken link or entered an incorrect URL. Let’s get you back on track!
        </p>
        <button onClick={goHome} className="not-found-button">
          Go Home
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
