import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmSignUp } from '@aws-amplify/auth'; // Correct import for Amplify v6

// Function to handle the confirmation of user sign-up
async function handleSignUpConfirmation(username2, confirmationCode2) {
  try {
    // Correctly call confirmSignUp with username and code as individual parameters
    //const response = await confirmSignUp(username, confirmationCode);
    const response = await confirmSignUp({
        username: username2,
        confirmationCode: confirmationCode2,
      });
    console.log('confirmSignUp response:', response);
    return response;
  } catch (error) {
    console.log('error confirming sign up', error);
    throw error; // Throw error to handle it in the calling function
  }
}

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('Verifying...');

  // Extract query parameters from the URL
  const query = new URLSearchParams(location.search);
  const data = query.get('data'); // Retrieve the encoded data parameter
  const code = query.get('code') || ''; // Retrieve the code from the URL

  // Initialize username variable
  let username = '';

  // Attempt to decode the data parameter if it exists
  if (data) {
    try {
      // Decode the Base64 encoded 'data' parameter and parse it as JSON
      const decodedData = JSON.parse(atob(data));
      //console.log('Decoded data:', decodedData); // Log decoded data to inspect contents

      // Extract the username or fallback to an empty string
      username = decodedData.userName?.trim() || ''; // Adjust the key based on actual content
      //console.log('Extracted username:', username); // Log the extracted username
    } catch (error) {
      //console.error('Failed to decode data:', error);
      setStatus('Invalid verification link. Failed to decode the required information.');
    }
  } else {
    console.log('No data parameter found in URL.');
  }

  useEffect(() => {
    const verifyUser = async () => {
      //console.log('Attempting to verify user with username:', username, 'and code:', code);

      try {
        // Ensure username and code are available and properly formatted
        if (username && code) {
          // Call handleSignUpConfirmation to confirm the user's sign-up
          const response = await handleSignUpConfirmation(username, code);

          setStatus('Email verified successfully! You can now log in.');
          // Redirect to the login page or another desired page after verification
          navigate('/auth', { replace: true });
        } else {
          console.warn('Missing username or code:', { username, code });
          setStatus('Invalid verification link. Missing username or code.');
        }
      } catch (error) {
        // Log detailed error information, including type and message
        console.error('Verification failed:', error);
        setStatus(`Verification failed: ${error.message || error}`);
      }
    };

    // Only attempt verification if username and code were successfully extracted
    if (username && code) {
      verifyUser();
    }
  }, [username, code, navigate]);

  //return <div>{status}</div>;
  return <div><h3>Processing</h3></div>;
};

export default VerifyEmail;
