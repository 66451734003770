import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'; // Import axios for making API requests

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [connectionId, setConnectionId] = useState(null);
  const socketRef = useRef(null);
  const clientIdRef = useRef(sessionStorage.getItem('clientId') || uuidv4());
  const messageQueueRef = useRef([]);
  const heartbeatIntervalRef = useRef(null);

  useEffect(() => {
    const maxReconnectAttempts = 5;
    let reconnectAttempts = 0;

    if (!localStorage.getItem('clientId')) {
      localStorage.setItem('clientId', clientIdRef.current);
    }

    const sendQueuedMessages = () => {
      while (messageQueueRef.current.length > 0 && socketRef.current.readyState === WebSocket.OPEN) {
        const message = messageQueueRef.current.shift();
        socketRef.current.send(message);
      }
    };

    const startHeartbeat = () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      heartbeatIntervalRef.current = setInterval(() => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify({ action: 'ping' }));
          console.log('Sent heartbeat ping');
        }
      }, 30000); // Send a ping every 30 seconds
    };

    const stopHeartbeat = () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
        heartbeatIntervalRef.current = null;
      }
    };

    const getUserFromToken = (token) => {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.sub;
      } catch (error) {
        console.error('Error parsing JWT token:', error);
        return null;
      }
    };

    const createPatchPayload = (newConnectionId) => {
      const payload = {};
    
      // Include all fields regardless of their value
      payload.clientId= newConnectionId || '';

      return payload;
    };

    const updateUserClientId = async (newConnectionId) => {
      try {
        const session = await fetchAuthSession();
        const jwtToken = session?.idToken?.jwtToken;
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
        const user = getUserFromToken(token);
  
        if (!user) {
          alert('Failed to retrieve user from JWT token.');
          return;
        }
  
        const headers = {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`,
        };
  
        const apiUrl = process.env.REACT_APP_API_URL; 
        const url = `${apiUrl}/users/${encodeURIComponent(user)}`;
        const patchData = createPatchPayload(newConnectionId);
  
        await axios.patch(url, patchData, { headers });

        console.log(`User updated with new clientId: ${newConnectionId}`);
      } catch (error) {
        console.error('Error updating user with new clientId:', error);
      }
    };

    const connectWebSocket = async () => {
      if (socketRef.current && (socketRef.current.readyState === WebSocket.CONNECTING || socketRef.current.readyState === WebSocket.OPEN)) {
        console.log('WebSocket is already connecting or connected');
        return;
      }

      try {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        const wsEnv = process.env.REACT_APP_SOCKET_API_URL; 
        const wsUrl = `${wsEnv}?Authorization=${encodeURIComponent(token)}`;

        socketRef.current = new WebSocket(wsUrl);

        socketRef.current.onopen = () => {
          try {
            const authMessage = JSON.stringify({ action: 'authenticate', token, clientId: clientIdRef.current });
            socketRef.current.send(authMessage);
            console.log('WebSocket connection established, awaiting connection ID from server...');
            reconnectAttempts = 0; // Reset reconnect attempts after a successful connection
            sendQueuedMessages();
            startHeartbeat(); // Start the heartbeat
          } catch (error) {
            console.error('Error during WebSocket authentication:', error);
          }
        };

        socketRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (data.connectionId) {
              setConnectionId(data.connectionId);
              console.log('Connection ID received from server: ' + data.connectionId);
              // Update the user with the new clientId
              updateUserClientId(data.connectionId);

            } else if (data.action === 'pong') {
              console.log('Received pong from server');
            } else {
              console.error('No connectionId found in the server response.');
            }
          } catch (error) {
            console.error('Error parsing server response:', error);
          }
        };

        socketRef.current.onclose = () => {
          stopHeartbeat(); // Stop the heartbeat when the connection closes
          if (reconnectAttempts < maxReconnectAttempts) {
            reconnectAttempts++;
            console.log(`Reconnecting... attempt ${reconnectAttempts}`);
            setTimeout(connectWebSocket, 5000); // Retry connection after 5 seconds
          } else {
            console.error('Max reconnect attempts reached. WebSocket connection failed.');
          }
        };

        socketRef.current.onerror = (error) => {
          console.error('WebSocket error:', error.message);
        };
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
      }
    };

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      stopHeartbeat(); // Stop heartbeat when component unmounts
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket: socketRef.current, clientId: clientIdRef.current, connectionId }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
