import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Add a new CSS file for Home component specific styles
import { FaFacebook, FaMedium, FaTwitter } from 'react-icons/fa'; // Add social media icons
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'; 

function Home() {
  const [showScroll, setShowScroll] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;
    const mailtoLink = `mailto:quicknotes.idealscribe@gmail.com?subject=Contact%20Form%20Submission&body=Name:%20${encodeURIComponent(name)}%0AEmail:%20${encodeURIComponent(email)}%0AMessage:%20${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="home">
      <header className="home-header">
        <div className="banner-left">
          <Link to="/">
            <img src="./logo.png" alt="Idealscribe" className="logo" />
          </Link>
          <h1 className="titleHome">Ideal Scribe</h1>
        </div>
        <nav className="nav-links">
          <Link to="/app">
            <button className="nav-button">Login</button>
          </Link>
          <Link to="/startup-guide" className="nav-link-black">Startup Guide</Link>
        </nav>
      </header>

      <div className="content">

        <section className="hero">
          <h1>Welcome to Ideal Scribe</h1>
          <p>Track your adventures efficiently and effortlessly</p>
          <Link to="/app">
            <button className="hero-button">Get Started</button>
          </Link>
        </section>

        <section className="downloads">
          <h2>Get the App</h2>
          <div className="download-buttons">
            <a href="https://play.google.com/store/apps" target="_blank" rel="noopener noreferrer">
              <img src="/images/pngegg-goog.png" alt="Google Play" className="download-badge" />
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img src="/images/pngegg.png" alt="App Store" className="download-badge" />
            </a>
          </div>
        </section>

        <section className="features">
          <h2>Awesome Features</h2>
          <div className="features-list">
            <div className="feature-item">
              <img src="/images/notes-email-large.png" alt="Feature 1" className="feature-image" />
              <h3>Quick Notes</h3>
              <p>Create notes anytime anywhere to never forget your next idea or adventure</p>
            </div>
            <div className="feature-item">
              <img src="/images/notes-ai-large.png" alt="Feature 2" className="feature-image" />
              <h3>Smart Notes</h3>
              <p>Use AI to help organize, categorize, and enhance your next trip</p>
            </div>
            <div className="feature-item">
              <img src="/images/notes-travel-large.png" alt="Feature 3" className="feature-image" />
              <h3>Organized Notes</h3>
              <p>Easily categorize and organize your ideas via web or mobile device</p>
            </div>
          </div>
        </section>

        <section className="video-bar">
          <h2>How it Works</h2>
          <video src="/videos/IdealScribePromo.mp4" controls className="how-it-works-video"></video>
        </section>

        <section className="track-time">
          <h2>Notes for any Adventure from Anywhere</h2>
          <p>Track your aspirations from your desktop or mobile device.</p>
          <img src="./images/idealScribeTravelLogo.png" alt="Track Time" className="section-image" />
        </section>

        <section className="daily-schedule">
          <h2>Built for Your Daily Schedule</h2>
          <p>Manage your ideas, notes, and tasks with ease.</p>
          <img src="./images/mobile-phone.png" alt="Daily Schedule" className="section-image" />
        </section>

        <section className="contact">
          <h2>Get in Touch</h2>
          <p>Contact us for more information</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Name" className="contact-input" value={formData.name} onChange={handleInputChange} />
            <input type="email" name="email" placeholder="Email" className="contact-input" value={formData.email} onChange={handleInputChange} />
            <textarea name="message" placeholder="Message" className="contact-textarea" value={formData.message} onChange={handleInputChange}></textarea>
            <button type="submit" className="contact-button">Send Message</button>
          </form>
        </section>

      </div>
      <footer className="home-footer">
          <div className="footer-left">
            <span>© {new Date().getFullYear()} Ideal Scribe. All rights reserved.</span>
          </div>
          <nav className="footer-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/support">Support</Link>
            <div className="social-links">
              <a href="https://medium.com" target="_blank" rel="noopener noreferrer"><FaMedium /></a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            </div>
          </nav>
        </footer>
    </div>
  );
}

export default Home;
