// AuthenticatedLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { WebSocketProvider } from './WebSocketContext';

const AuthenticatedLayout = () => {
  return (
    <WebSocketProvider>
      <Outlet /> {/* This will render the nested routes */}
    </WebSocketProvider>
  );
};

export default AuthenticatedLayout;
