import React, { useEffect } from 'react';
import './Support.css';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'; 

const Support = () => {
  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  
  return (
    <div className="support">
      <h1>Support</h1>
      <section>
        <h2>Contact Us</h2>
        <p>
          Need help? Reach out to our support team by emailing us at <a href="mailto:support@idealscribe.com">support@idealscribe.com</a>.
        </p>
      </section>
      <section>
        <h2>FAQs</h2>
        <p>
          Find answers to common questions below.
        </p>
        <div className="faq">
          <h3>How do I create an account?</h3>
          <p>
            You can create an account by clicking on the 'Sign Up' button on the homepage and following the instructions.
          </p>
        </div>
        <div className="faq">
          <h3>How do I reset my password?</h3>
          <p>
            To reset your password, click on 'Forgot Password' on the login page and follow the instructions.
          </p>
        </div>
        <div className="faq">
          <h3>How can I contact customer support?</h3>
          <p>
            You can contact customer support by emailing <a href="mailto:support@idealscribe.com">support@idealscribe.com</a>.
          </p>
        </div>
        <div className="faq">
          <h3>Where can I find more tutorials?</h3>
          <p>
            Visit our help center for more tutorials and guides on using IdealScribe.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Support;
