import React, { useState, useEffect, useRef } from 'react';
import './CreateNote.css';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWebSocket } from './WebSocketContext';
import ReactGA from 'react-ga4';

const CreateNote = () => {
  const { socket, clientId, connectionId  } = useWebSocket(); // Get socket and clientId from context
  const clientIdRef = useRef(clientId);
  const [formData, setFormData] = useState({
    emailSubject: '',
    urlsPresent: false,
    bodyText: '',
    urls: '',
    manualTags: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  
  useEffect(() => {
    clientIdRef.current = clientId;
  }, [clientId]);

  useEffect(() => {
    if (location.state && location.state.manualTags) {
      const { Address, Lat_Long, geolat_long } = location.state.manualTags;
      const manualTagsArray = [
        Address && `Address:${Address}`, 
        Lat_Long && `Lat_Long:${Lat_Long}`,
        geolat_long && `Lat_Long:${Lat_Long}`,
      ].filter(Boolean); // Filter out falsy values
      const manualTagsString = manualTagsArray.join(',');
      setFormData(prevData => ({
        ...prevData,
        manualTags: manualTagsString,
      }));
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const newErrors = {};
    if (!formData.emailSubject) newErrors.emailSubject = 'Note Name is required';
    if (!formData.bodyText) newErrors.bodyText = 'Note Text is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const urlsArray = formData.urls ? formData.urls.split(',').map(url => url.trim()) : [];
    const manualTagsArray = formData.manualTags ? formData.manualTags.split(',').reduce((tags, tag) => {
      const [key, value] = tag.split(':');
      tags[key.trim()] = value.trim();
      return tags;
    }, {}) : {};

    const urlsPresent2 = urlsArray.length > 0;

    const currentTimestamp = new Date().toISOString();

    const noteData = {
      emailSubject: formData.emailSubject,
      urlsPresent: urlsPresent2,
      bodyText: formData.bodyText,
      urls: urlsArray,
      //clientId: connectionId , // Use clientId directly from WebSocket context
      //noteCreationDate: currentTimestamp, // Set the creation date
      //lastEdited: currentTimestamp, // Set the last edited date
      manualTags: manualTagsArray,
    };
    console.log('ClientID is: ', clientIdRef.current);
    //console.log('address is: ', manualTagsArray.current);
    //console.log('address is: ', manualTagsArray.Address);
    //console.log('address is: ', manualTagsArray.Address.current);
    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();

      const apiUrl = process.env.REACT_APP_API_URL; 

      //const response = await axios.post('https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes', noteData, {
      const response = await axios.post(`${apiUrl}/notes`, noteData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Note created successfully', response.data);
      navigate('/app'); // Navigate back to the main app or any other route
    } catch (error) {
      console.error('Error creating note', error);
    }
  };

  return (
    <div className="create-note-modal">
      <div className="create-note-content">
        <h2>Create Note</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Note Name:
            <input type="text" name="emailSubject" value={formData.emailSubject} onChange={handleInputChange} />
            {errors.emailSubject && <span className="error">{errors.emailSubject}</span>}
          </label>
          <label>
            Note Text:
            <textarea name="bodyText" value={formData.bodyText} onChange={handleInputChange}></textarea>
            {errors.bodyText && <span className="error">{errors.bodyText}</span>}
          </label>
          <label>
            URLs (comma separated):
            <input type="text" name="urls" value={formData.urls} onChange={handleInputChange} />
          </label>
          <label>
            Manual Tags (comma separated key:value):
            <input type="text" name="manualTags" value={formData.manualTags} onChange={handleInputChange} />
          </label>
          <button type="submit" className="create-note-button">Create Note</button>
          <button type="button" className="create-note-button cancel" onClick={() => navigate('/app')}>Cancel</button>
        </form>
        <h3>Note creation may take up to 1 minute for processing.</h3>
      </div>
    </div>
  );
};

export default CreateNote;
