import React, { useEffect } from 'react';
import './TermsOfService.css';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'; 

const TermsOfService = () => {
  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to IdealScribe. These terms and conditions outline the rules and regulations for the use of our website and services.
        </p>
      </section>
      <section>
        <h2>2. Acceptance of Terms</h2>
        <p>
          By accessing this website, you accept these terms and conditions in full. Do not continue to use IdealScribe if you do not accept all of the terms and conditions stated on this page.
        </p>
      </section>
      <section>
        <h2>3. Description of Service</h2>
        <p>
          IdealScribe is an online platform providing personalized recommendations and notes. We do not guarantee the accuracy of any information provided.
        </p>
      </section>
      <section>
        <h2>4. User Responsibilities</h2>
        <p>
          Users must be at least 18 years of age to use our services. You agree to use the service only for lawful purposes and to comply with all applicable laws.
        </p>
      </section>
      <section>
        <h2>5. Limitation of Liability</h2>
        <p>
          IdealScribe will not be held responsible for any damages arising from the use of our service. Use at your own risk.
        </p>
      </section>
      <section>
        <h2>6. Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Changes will be posted on this page.
        </p>
      </section>
      <section>
        <h2>7. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at support@idealscribe.com.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
