import React, { useState, useEffect } from 'react';
import { signUp, signIn, resetPassword, confirmResetPassword, resendSignUpCode } from '@aws-amplify/auth'; // Correct imports
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useLocation } from 'react-router-dom';
import './CustomSignIn.css';
import ReactGA from 'react-ga4';

const CustomSignIn = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordsMatch, setNewPasswordsMatch] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaKey = '6LfgcCEqAAAAAImE1F2rQD8vG_ADWXQ0senGNdND';
  const from = location.state?.from?.pathname || '/app';

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    if (!captchaToken) {
      setError('Please complete the CAPTCHA');
      setLoading(false);
      return;
    }

    if (isSignUp && password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      if (isSignUp) {
        // Sign up the user with email verification enforced
        await signUp({
          username: email,
          password: password,
          attributes: { email },
        });

        setError('Sign up successful. Please check your email for verification.');
        setIsSignUp(false);
      } else {
        await signIn({
          username: email,
          password: password,
        });
        setError('');
        navigate(from);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setError('Account created but not confirmed. Please check your email for verification.');
        try {
          await resendSignUpCode(email);
          setError('Verification code resent. Please check your email.');
        } catch (resendError) {
          setError(`Error resending verification code: ${resendError.message}`);
        }
      } else {
        setError(err.message);
      }
    }
    setLoading(false);
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await resetPassword({ username: email });
      setError('A reset code has been sent to your email.');
      setIsResetPassword(true);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  const handleResetPassword = async () => {
    if (!email || !resetCode || !newPassword || !confirmNewPassword) {
      setError('Please fill in all fields to reset your password.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      setNewPasswordsMatch(false);
      return;
    }

    setLoading(true);
    setError('');
    setNewPasswordsMatch(true);

    try {
      await confirmResetPassword({ username: email, confirmationCode: resetCode, newPassword });
      setError('Password has been reset successfully. You can now log in.');
      setIsResetPassword(false);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setError('');
    setCaptchaToken(null);
    setPasswordsMatch(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (isSignUp) {
      setPasswordsMatch(e.target.value === confirmPassword);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(password === e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordsMatch(e.target.value === confirmNewPassword);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    setNewPasswordsMatch(newPassword === e.target.value);
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2>{isSignUp ? 'Sign Up' : isResetPassword ? 'Reset Password' : 'Login'}</h2>
        {error && <p className="error-message">{error}</p>}
        {isResetPassword ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="input-group">
              <label htmlFor="resetEmail">Email:</label>
              <input
                type="email"
                id="resetEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="resetCode">Reset Code:</label>
              <input
                type="text"
                id="resetCode"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="newPassword">New Password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="confirmNewPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                required
              />
              {!newPasswordsMatch && <p className="error-message">Passwords do not match</p>}
            </div>
            <button
              type="button"
              className="auth-button"
              onClick={handleResetPassword}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Reset Password'}
            </button>
            <div className="toggle-link" onClick={() => setIsResetPassword(false)}>
              Back to Login
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            {isSignUp && (
              <div className="input-group">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                {!passwordsMatch && <p className="error-message">Passwords do not match</p>}
              </div>
            )}
            <ReCAPTCHA
              sitekey={recaptchaKey}
              onChange={handleCaptchaChange}
              className="captcha"
            />
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Loading...' : isSignUp ? 'Sign Up' : 'Login'}
            </button>
            {!isSignUp && (
              <div className="toggle-link" onClick={handleForgotPassword}>
                Forgot Password?
              </div>
            )}
          </form>
        )}
        <div className="toggle-link" onClick={toggleSignUp}>
          {isSignUp ? 'Already have an account? Login here' : "Don't have an account? Sign up here"}
        </div>
      </div>
    </div>
  );
};

export default CustomSignIn;
