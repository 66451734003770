import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';

const withAuth = (Component) => {
  return (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
      const checkAuth = async () => {
        try {
          await getCurrentUser();
          setIsAuthenticated(true);
        } catch (error) {
          setIsAuthenticated(false);
        }
      };

      checkAuth();
    }, []);

    if (isAuthenticated === null) {
      // Optionally, return a loading spinner or similar while checking auth state
      return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      return <Navigate to="/auth" replace />;
    }

    // Render the component if authenticated
    return (
          <Component {...props} />
      );
  };
};

export default withAuth;
