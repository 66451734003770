import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StartupGuide.css';
import { FaFacebook, FaMedium, FaTwitter } from 'react-icons/fa'; 
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'; 

function StartupGuide() {
  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return (
    <div className="startup-guide">
      <header className="home-header">
      <div className="banner">
        <div className="banner-left">
          <Link to="/">
            <img src="./logo.png" alt="Idealscribe" className="logo"/>
          </Link>
          <Link to="/" className="title-link">
            <h1 className="title">Ideal Scribe</h1>
          </Link>
        </div>
        <nav className="nav-links">
          <Link to="/app" className="nav-button-link">
            <button className="nav-button">Login</button>
          </Link>
          <Link to="/" className="nav-link">Home</Link>
        </nav>
      </div>
      </header>
      
      <div className="content">
        <h1 className="page-title">Startup Guide</h1>
        <p className="intro">
        This guide will help you get started with sending quick notes and utilizing our system's powerful features to organize your notes effectively. <br/> <br/>
        Whether you are jotting down ideas, tracking activities, or organizing tasks, Ideal Scribe is here to enhance your productivity.</p>

        <section className="section">
          <div className="section-content">
            <div className="section-text">
              <h2>1. Sending Quick Notes via Email</h2>
              <p>Do you have a quick note you need to capture on the go? Simply send an email to <strong>scribe@quicknotes.idealscribe.com</strong>. This allows you to effortlessly add notes to your Ideal Scribe account anytime, anywhere. Just draft an email with your note content and hit send—it's that easy!</p>
            </div>
            <img src="images/sending-emails.png" alt="Send Email" className="illustration"/>
          </div>
        </section>

        <section className="section">
          <div className="section-content">
            <div className="section-text">
              <h2>2. Automated Intelligence for Notes</h2>
              <p>Our system leverages advanced artificial intelligence to take your note-taking to the next level. When you send in a note, our AI will automatically:</p>
              <ul>
                <li><strong>Recommend Categories:</strong> Our AI will suggest relevant categories for your notes based on the content, making it easier for you to keep everything organized.</li>
                <li><strong>Extract Location Details:</strong> Any location-related information in your notes will be identified and tagged, so you can always remember where your events or activities are happening.</li>
                <li><strong>Suggest Best Times to Visit:</strong> For activities or visits, our AI will provide optimal times based on various factors, ensuring you make the most out of your plans.</li>
              </ul>
              <p>This intelligent automation helps you save time and ensures your notes are organized efficiently, allowing you to focus on what really matters.</p>
            </div>
            <img src="/images/notes-smart-large.png" alt="AI" className="illustration"/>
          </div>
        </section>

        <section className="section">
          <div className="section-content">
            <div className="section-text">
              <h2>3. Using Manual Tags for Enhanced Organization</h2>
              <p>To further refine the AI suggestions and organize your notes exactly the way you want, you can use manual tags. These tags can be added directly within your notes using the following formats:</p>
              <ul>
                <li>Using a colon (<code>:</code>): <code>color: red</code></li>
                <li>Using a hash (<code>#</code>): <code>#color red</code></li>
              </ul>
              <p>For example, if you want to set the color of a note to red, you can include either of the following in your note:</p>
              <ul>
                <li><code>color: red</code></li>
                <li><code>#color red</code></li>
              </ul>
              <p>Manual tags give you the flexibility to customize your notes according to your preferences, making it easier to retrieve and categorize them later.</p>
            </div>
            <img src="images/tags.png" alt="Tags" className="illustration"/>
          </div>
        </section>

        <section className="section">
          <div className="section-content">
            <div className="section-text">
              <h2>4. List of Manual Tag Categories</h2>
              <p>To help you get the most out of your notes, here is a list of categories you can use to manually tag and organize your notes. These tags will help you classify and manage your notes effectively:</p>
              <ul className="tag-list">
                <li><strong>#Activity</strong> (e.g., hike, concert, bike ride)</li>
                <li><strong>#Season</strong> (e.g., summer, fall, winter, spring)</li>
                <li><strong>#color-red</strong></li>
                <li><strong>#name</strong></li>
                <li><strong>#description</strong></li>
                <li><strong>#address</strong></li>
                <li><strong>#website</strong></li>
                <li><strong>#season</strong></li>
                <li><strong>#month</strong></li>
                <li><strong>#day</strong></li>
                <li><strong>#time</strong></li>
                <li><strong>#nearby</strong></li>
                <li><strong>#tags</strong></li>
                <li><strong>#url</strong></li>
              </ul>

              <h3>Examples</h3>
              <p>Here are some examples of how you can use these tags in your notes:</p>
              <ul className="examples">
                <li><code>#Activity hike</code> - Tagging a note about a hiking trip.</li>
                <li><code>#Season summer</code> - Categorizing an event happening in the summer.</li>
                <li><code>#color-red</code> - Setting the color of a note to red for easy identification.</li>
                <li><code>#name John's Birthday</code> - Naming a note for John's birthday celebration.</li>
                <li><code>#description Company meeting at HQ</code> - Providing a description for a company meeting.</li>
                <li><code>#address 123 Main St, Springfield</code> - Adding an address for a meeting location.</li>
                <li><code>#website www.example.com</code> - Including a relevant website link in your note.</li>
                <li><code>#season fall</code> - Specifying the season for an event.</li>
                <li><code>#month October</code> - Indicating the month of an activity.</li>
                <li><code>#day Monday</code> - Marking the day of the week.</li>
                <li><code>#time 10:00 AM</code> - Adding a specific time to a note.</li>
                <li><code>#nearby Central Park</code> - Mentioning a nearby landmark for context.</li>
                <li><code>#tags important, work</code> - Adding multiple tags for better organization.</li>
                <li><code>#url http://example.com</code> - Including a URL for reference.</li>
              </ul>

              <h3>Categorization of Notes by Color</h3>
              <p>You can categorize your notes manually by either adding a tag when creating your note, or by editing a note and adding a tag with the name "categorization" or "color" and then selecting the appropriate color. If no color is provided, then the AI recommendation will automatically assign a color based on the following criteria:</p>
              <ul className="colors">
                <li><code>Blue="Travel"</code></li>
                <li><code>Green="To do"</code></li>
                <li><code>Red="Shopping"</code></li>
                <li><code>Purple="Health"</code></li>
                <li><code>Yellow="Food"</code></li>
                <li><code>Orange="Entertainment"</code></li>
                <li><code>Light Blue="Reminder" </code> </li>
              </ul>

              <p>By utilizing these tags, you can ensure that your notes are categorized in a way that makes them easy to find and use later. Happy note-taking!</p>
            </div>
          </div>
        </section>
      </div>
      <footer className="footer">
        <div className="footer-left">
          <span>© {new Date().getFullYear()} Ideal Scribe. All rights reserved.</span>
        </div>
        <nav className="footer-links">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/support">Support</Link>
          <div className="social-links">
            <a href="https://medium.com" target="_blank" rel="noopener noreferrer"><FaMedium /></a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          </div>
        </nav>
      </footer> 
    </div>
  );
}

export default StartupGuide;
