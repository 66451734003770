import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <section>
        <h2>1. Introduction</h2>
        <p>
          Your privacy is important to us. This privacy policy explains how we collect, use, and protect your information.
        </p>
      </section>
      <section>
        <h2>2. Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, and usage data when you use our services.
        </p>
      </section>
      <section>
        <h2>3. Use of Information</h2>
        <p>
          The information we collect is used to provide and improve our services, communicate with you, and comply with legal obligations.
        </p>
      </section>
      <section>
        <h2>4. Data Sharing</h2>
        <p>
          We do not share your personal information with third parties except to comply with the law, protect our rights, or with your consent.
        </p>
      </section>
      <section>
        <h2>5. Data Security</h2>
        <p>
          We implement industry-standard security measures to protect your personal information from unauthorized access and disclosure.
        </p>
      </section>
      <section>
        <h2>6. Changes to Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. Changes will be posted on this page.
        </p>
      </section>
      <section>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at privacy@idealscribe.com.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
